<template>
  <el-row class="table">
    <el-table
      :data="dataList"
      tooltip-effect="dark"
      border
      :summary-method="getSummaries"
      show-summary
      style="width:100%">
      <el-table-column label="上课时间" align="center" width="230">
        <template slot-scope="scope">
          <span>{{ scope.row.startTime | formatDateStart}} {{scope.row.endTime | formatDateEnd}}</span>
        </template>
      </el-table-column>
      <el-table-column label="班级名称" align="center" prop="classTitle" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="班级校区" prop="classCampusTitle" :show-overflow-tooltip="true" align="center"></el-table-column>
      <el-table-column label="课时（小时）" align="center" prop="lessonLong">
        <template slot-scope="scope">
          <span v-text="changeHourMinutestr(scope.row.lessonLong)"></span>
        </template>
      </el-table-column>
      <el-table-column label="出勤人数（人）" align="center" prop="attend"></el-table-column>
      <el-table-column label="课堂角色" align="center" prop="lessonRole"></el-table-column>
      <!-- <el-table-column label="课时费" align="center" prop="salaryLong"></el-table-column>
      <el-table-column label="课系费" align="center" prop="salaryLesson"></el-table-column> -->
      <!-- <el-table-column label="小计" align="center" prop="salaryTotal"></el-table-column> -->
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>

  </el-row>
</template>

<script>
import { getListSalaryMy } from '@/api/statistics'


export default {
  created() {
    this.getListSalaryMy()
  },
  data () {
    return {
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
    }
  },
  methods: {
    async getListSalaryMy () {
      const res = await getListSalaryMy({
        ssid: this.$route.query.id,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize
      })
      this.dataList = res.body.list
      this.pagination.total = res.body.total
    },

    search() {
      this.pagination.currentPage = 1
      this.getListSalaryMy()
    },

    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          if (column.property === 'lessonLong') {
            sums[index] = this.changeHourMinutestr(sums[index]) + ' 小时'
          } else if (column.property === 'attend') {
            sums[index] += ' 人';
          } else {
            sums[index];
          }
        } else {
          sums[index] = 'N/A';
        }
      });

      return sums;
    },

    // 分页
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getListSalaryMy()
    },
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getListSalaryMy()
    },

    changeHourMinutestr(str) {
      if (str % 60 === 0) {
        return str / 60
      } else {
        return Number(str / 60).toFixed(2)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/table.scss";
</style>
